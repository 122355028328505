.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=range] {
  -webkit-appearance: none;
  -webkit-transform: rotate(-90deg);
  margin-top: 50px;
  margin-bottom: 50px;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 250px;
  height: 6px;
  background: #ffffff;
  border: none;
  border-radius: 100px;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #ffffff;
  margin-top: -7px;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #ddd;
}

.gndm-icon:hover img:first-child {
  display: flex!important;
}

.gndm-icon:hover img:nth-child(2) {
  display: none!important;
}

.single-thumb {
  background-color: #ffffff !important;
}

.single-thumb > div:nth-of-type(1) {
  display: none !important;
}

.single-thumb > div:nth-of-type(2) {
  background-color: #ffffff !important;
  cursor: pointer !important;
  width: 20px !important;
  height: 20px !important;
}

.single-thumb > input:nth-of-type(1) {
  display: none !important;
}

.single-thumb > input:nth-of-type(2) {
  display: none !important;
}

.single-thumb > div:nth-of-type(3) {
  background: #ffffff !important;
}

.single-thumb.range-slider[data-vertical] {
  width: 3px !important;
}


.single-thumb-mobile-vertical {
  background-color: #ffffff !important;
}

.single-thumb-mobile-vertical > div:nth-of-type(1) {
  display: none !important;
}

.single-thumb-mobile-vertical > div:nth-of-type(2) {
  background-color: #ffffff !important;
  cursor: pointer !important;
  width: 10px !important;
  height: 10px !important;
}

.single-thumb-mobile-vertical > input:nth-of-type(1) {
  display: none !important;
}

.single-thumb-mobile-vertical > input:nth-of-type(2) {
  display: none !important;
}

.single-thumb-mobile-vertical > div:nth-of-type(3) {
  background: #ffffff !important;
}

.single-thumb-mobile-vertical.range-slider[data-vertical] {
  width: 3px !important;
}



.single-thumb-mobile {
  background-color: #ffffff !important;
}

.single-thumb-mobile > div:nth-of-type(1) {
  display: none !important;
}

.single-thumb-mobile > div:nth-of-type(2) {
  background-color: #ffffff !important;
  cursor: pointer !important;
  width: 10px !important;
  height: 10px !important;
}

.single-thumb-mobile > input:nth-of-type(1) {
  display: none !important;
}

.single-thumb-mobile > input:nth-of-type(2) {
  display: none !important;
}

.single-thumb-mobile > div:nth-of-type(3) {
  background: #ffffff !important;
}

.single-thumb-mobile.range-slider {
  height: 2px !important;
}

.McButton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;  /* Equivalent to -(@bar-w / 2) where @bar-w is 44px */
  width: 30px;        /* Equivalent to @bar-w */
  height: 20px;       /* Equivalent to (@bar-w * 3) / 4 */
  cursor: pointer;
}

.McButton-b-white b {
  position: absolute;
  left: 0;
  width: 30px;        /* Equivalent to @bar-w */
  height: 3px;        /* Equivalent to @bar-h */
  background-color: white; /* Equivalent to @bar-color */
}

.McButton-b-black b {
  position: absolute;
  left: 0;
  width: 30px;        /* Equivalent to @bar-w */
  height: 3px;        /* Equivalent to @bar-h */
  background-color: black; /* Equivalent to @bar-color */
}

.McButton b:nth-child(1) {
  top: 0;
}

.McButton b:nth-child(2) {
  top: 50%;
}

.McButton b:nth-child(3) {
  top: 100%;
}

.image-mapper-shape {
  background-color: transparent!important;
}

.interactive-floor-plan-map a {
  opacity: 0!important;
}

.custom-hotspot-wrapper {
  z-index: 9999999999 !important;
}