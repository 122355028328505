body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For Chrome, Edge, and Safari */
.scrollable-div::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.scrollable-div {
  scrollbar-width: none;
}

/* For IE and Edge */
.scrollable-div {
  -ms-overflow-style: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 900;
    font-style: normal;
    src: url('./assets/fonts/untitled-sans/UntitledSans-Black.otf') format('opentype');
  }

  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 900;
    font-style: italic;
    src: url('./assets/fonts/untitled-sans/UntitledSans-BlackItalic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 700;
    font-style: normal;
    src: url('./assets/fonts/untitled-sans/UntitledSans-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 700;
    font-style: italic;
    src: url('./assets/fonts/untitled-sans/UntitledSans-BoldItalic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 300;
    font-style: normal;
    src: url('./assets/fonts/untitled-sans/UntitledSans-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 300;
    font-style: italic;
    src: url('./assets/fonts/untitled-sans/UntitledSans-LightItalic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 500;
    font-style: normal;
    src: url('./assets/fonts/untitled-sans/UntitledSans-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 500;
    font-style: italic;
    src: url('./assets/fonts/untitled-sans/UntitledSans-MediumItalic.otf') format('opentype');
  }

  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 400;
    font-style: normal;
    src: url('./assets/fonts/untitled-sans/UntitledSans-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Untitled Sans';
    font-weight: 400;
    font-style: italic;
    src: url('./assets/fonts/untitled-sans/UntitledSans-RegularItalic.otf') format('opentype');
  }

}

.vertical-slider {
  writing-mode: bt-lr;
  appearance: slider-vertical;
  width: 21px;
  height: 150px;
  margin: 5px;
}

.vertical-slider::-webkit-slider-thumb {
  width: 21px;
  height: 21px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

:root {
  --font-satoshi: "Satoshi", sans-serif;
  --safe-padding-top: env(safe-area-inset-top, 32px);
  --safe-padding-bottom: env(safe-area-inset-bottom, 32px);
  --safe-margin-top: env(safe-area-inset-top, 32px);
  --safe-margin-bottom: env(safe-area-inset-bottom, 32px);
}

@layer utilities {
  .pb-safe {
    padding-bottom: max(var(--safe-padding-bottom), 80px);
  }

  .pt-safe {
    padding-top: max(var(--safe-padding-top), 32px);
  }

  .mb-safe {
    margin-bottom: max(var(--safe-margin-bottom), 80px);
  }

  .mt-safe {
    margin-top: max(var(--safe-margin-top), 32px);
  }
}